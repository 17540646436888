/* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";

:root {
  --app-color: #e93000;
  --app-color-hover: #b92e16;
}


/* Início classes Botão Upload Files*/
.custom-file-input {
    color: transparent;
    border-radius: 8px;
  }

  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

.custom-file-input::before {
    content: "Selecionar imagem";
    color: #ffffff;
    display: none;
    background: -webkit-linear-gradient(top, #1890ff, #1890ff);
    border: 1px solid #ffffff;
    border-radius: 2px;
    padding: 0.2rem 1.5rem;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
  }

  .custom-file-input:hover::before {
    border-color: #ffffff;
  }

  .custom-file-input:active {
    outline: 0;
  }

  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #1890ff, #1890ff);
  }

  .labelButtonUploadImage {
    background-color: #1890ff;
    white-space: nowrap;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    height: 32px;
    min-width: 28px;
    padding: 8px;
    align-items: center;
    display: flex;
    overflow-wrap:normal;

}

.labelButtonUploadImage:hover {
    background-color: #42a6bd;
}

.ButtonDefault {
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: 32px;
  min-width: 104px;
  padding: 8px;
  align-items: center;
  display: flex;
  overflow-wrap:normal;
  text-align: center;
  justify-content: center;

}

.ButtonDefault:hover {
  background-color: #42a6bd;
}
/* Fim classes Botão upload files */

.ngx-toastr {
  width: 550px !important;
}

@media (max-width: 768px) {
  .ngx-toastr {
    width: 350px !important;
    font-size: 16px;
  }
}
@media (min-width: 769px) {
  .ngx-toastr {
    width: 550px !important;
    font-size: 16px;
  }
}
